import React, { useEffect, useState } from 'react'
import Left from './Left';
import Right from './Right';
function Notification({langData}) {
    let userid = localStorage.getItem('userid');
    let localDocs = localStorage.getItem('localDocs');
    //let dil = sessionStorage.getItem('dil');
    const [getNotify, setGetNotify] = useState([]);
    const fetchGetNotify = () => {
        fetch(localDocs+'/rn-adaptor/l-notifications?&userid=' + userid)
            .then(responseGPo => responseGPo.json())
            .then(getNotify => setGetNotify(getNotify))
            .catch(errorNtf => console.error('Fetch error : ', errorNtf));
    }
    useEffect(() => {
        fetchGetNotify();
    }, []);
console.log(getNotify);

    let lastArea = "ntfAll";
    function opArea(area) {
        document.getElementById(lastArea + "Tab").classList.remove('ntfFilterActive');
        document.getElementById(lastArea).style.display = "none";
        document.getElementById(area + "Tab").classList.add('ntfFilterActive');
        document.getElementById(area).style.display = "block";
        lastArea = area;
    }
    let stockimg = localStorage.getItem('stockimg');
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='midArea'>
                    <div className='midAreaC'>
                        <div className='ntfHeader'>
                            <a onClick={()=>window.history.back()} className='leftArrow'><img src={require('../assets/icons/arrow.webp')} /></a>
                            <h3>{langData.notifications}</h3>
                            <div className='ntfFilter'>
                                <a id="ntfAllTab" className="ntfFilterActive" onClick={() => opArea('ntfAll')}>{langData.all}</a>
                                <a id="ntfSubscribeTab" onClick={() => opArea('ntfSubscribe')}>{langData.subscribe}</a>
                                <a id="ntfLikesTab" onClick={() => opArea('ntfLikes')}>{langData.likes}</a>
                                <a id="ntfCommentsTab" onClick={() => opArea('ntfComments')}>{langData.comments}</a>
                                <a id="ntfTipsTab" onClick={() => opArea('ntfTips')}>{langData.tips}</a>
                            </div>
                        </div>
                        {getNotify ? (
                            <div className='ntfBody'>
                                <div className='notify' id='ntfAll'>
                                    {getNotify.map(ntfy =>
                                        <div key={ntfy.id} className='notifySub'>
                                            <a href={'user-detail/' + ntfy.otherid}>
                                                {ntfy.pp ? (<img src={ntfy.pp} />) : (<img src={stockimg} alt={'Notify' + ntfy.ptitle} />)}
                                            </a>
                                            <div className='notifyDatas'>
                                                <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                                {ntfy.type === "Comment" ? (
                                                    <p>You have a new comments on <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a>
                                                        from <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                                    </p>
                                                ) : ('')}
                                                {ntfy.type === "Buy Post" ? (
                                                    <p><a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> bought your
                                                        <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                                ) : ('')}
                                                {ntfy.type === "Like" ? (
                                                    <p><a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> like your
                                                        <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                                ) : ('')}
                                                {ntfy.type === "Subscribe" ? (
                                                    <p>You have a new subscriptions : <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>.</p>
                                                ) : ('')}
                                                {ntfy.type === "Tip" ? (
                                                    <p>You have tip by <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> from
                                                        <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                                ) : ('')}
                                                <span>{ntfy.date}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='notify hidden' id='ntfSubscribe'>
                                    {getNotify.map(ntfy => ntfy.type === "Subscribe" && (
                                        <div className='notifySub'>
                                            <a href={'user-detail/' + ntfy.otherid}>
                                                {ntfy.pp ? (<img src={ntfy.pp} />) : (<img src={stockimg} alt={'Notify' + ntfy.ptitle} />)}
                                            </a>
                                            <div className='notifyDatas'>
                                                <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>

                                                <p>You have a new subscriptions : <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>.</p>
                                                <span>{ntfy.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='notify hidden' id='ntfLikes'>
                                    {getNotify.map(ntfy => ntfy.type === "Like" && (
                                        <div className='notifySub'>
                                            <a href={'user-detail/' + ntfy.otherid}>
                                                {ntfy.pp ? (<img src={ntfy.pp} />) : (<img src={stockimg} alt={'Notify' + ntfy.ptitle} />)}
                                            </a>
                                            <div className='notifyDatas'>
                                                <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                                <p><a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> like your
                                                    <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                                <span>{ntfy.date}</span>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                <div className='notify hidden' id='ntfComments'>
                                    {getNotify.map(ntfy => ntfy.type === "Comment" && (
                                        <div className='notifySub'>
                                            <a href={'user-detail/' + ntfy.otherid}>
                                                {ntfy.pp ? (<img src={ntfy.pp} />) : (<img src={stockimg} alt={'Notify' + ntfy.ptitle} />)}
                                            </a>
                                            <div className='notifyDatas'>
                                                <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                                <p>You have a new comments on <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a>
                                                    from <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                                </p>
                                                <span>{ntfy.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='notify hidden' id='ntfTips'>
                                    {getNotify.map(ntfy => ntfy.type === "Tip" && (
                                        <div className='notifySub'>
                                            <a href={'user-detail/' + ntfy.otherid}>
                                                {ntfy.pp ? (<img src={ntfy.pp} />) : (<img src={stockimg} alt={'Notify' + ntfy.ptitle} />)}
                                            </a>
                                            <div className='notifyDatas'>
                                                <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                                <p>You have tip by <a href={'/user-detail/' + ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> from
                                                    <a href={'/post/' + ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                                <span>{ntfy.date}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/*ntfy.type === "Like" ?(
                                <div className='notify hidden' id='ntfLikes'>
                                    <a href={'user-detail/'+ntfy.otherid}>
                                        {ntfy.pp ? (<img src={ntfy.pp}/>):(<img src={stockimg} alt={'Notify' + ntfy.ptitle}/>)}
                                    </a>
                                    <div className='notifyDatas'>
                                        <a href={'/user-detail/'+ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                        <p><a href={'/user-detail/'+ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> like your
                                        <a href={'/post/'+ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                        <span>{ntfy.date}</span>
                                    </div>
                                </div>
                                ):('')}
                                {ntfy.type === "Comment" ?(
                                <div className='notify hidden' id='ntfComments'>
                                    <a href={'user-detail/'+ntfy.otherid}>
                                        {ntfy.pp ? (<img src={ntfy.pp}/>):(<img src={stockimg} alt={'Notify' + ntfy.ptitle}/>)}
                                    </a>
                                    <div className='notifyDatas'>
                                        <a href={'/user-detail/'+ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                        <p>You have a new comments on <a href={'/post/'+ntfy.pid}> {ntfy.ptitle} </a>
                                            from <a href={'/user-detail/'+ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                        </p>
                                        <span>{ntfy.date}</span>
                                    </div>
                                </div>
                                ):('')}
                                {ntfy.type === "Tip" ?(
                                <div className='notify hidden' id='ntfTips'>
                                    <a href={'user-detail/'+ntfy.otherid}>
                                        {ntfy.pp ? (<img src={ntfy.pp}/>):(<img src={stockimg} alt={'Notify' + ntfy.ptitle}/>)}
                                    </a>
                                    <div className='notifyDatas'>
                                        <a href={'/user-detail/'+ntfy.otherid}>{ntfy.name} {ntfy.surname}</a>
                                        <p>You have tip by <a href={'/user-detail/'+ntfy.otherid}>{ntfy.name} {ntfy.surname}</a> from
                                        <a href={'/post/'+ntfy.pid}> {ntfy.ptitle} </a> post.</p>
                                        <span>{ntfy.date}</span>
                                    </div>
                                </div>
                                ):('')*/}
                            </div>
                        ) : ('')}
                    </div>
                    <div className='rightAreaC'>
                        <Right />
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Notification;