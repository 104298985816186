import React, { Component, useEffect, useState } from 'react'
import { ScrollRestoration, UNSAFE_DataRouterStateContext, useParams } from 'react-router-dom';
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
function Posts({ langData }) {
    let { uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');
    const [getPosts, setGetPosts] = useState([]);
    const [getPost, setGetPost] = useState([]);
    const [getCat, setGetCat] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    //const [selectedFiles, setSelectedFiles] = useState([]);
    const [userData, setUserData] = useState([]);
    const fetchUserData = () => {
        fetch(localDocs+'/rn-adaptor/l-user?id='+userid)
        .then(responseUD => responseUD.json())
        .then(userData => {
            setUserData(userData);
            if(userData){
                //console.log(userData);
                if(userData.isCreator == 1){}else{window.location.href='/';}
            }
        })
        .catch(errorUD => console.log('Fetch error : ', errorUD));
    }
    const parser = new DOMParser();
    let type = "all";
    let order = "desc";
    function orderChange(gOrder){
        if(gOrder == "desc"){
            document.getElementById('otcDESC').style.display="none";
            document.getElementById('otcASC').style.display="block";
            order = "desc";
            document.getElementById('orderText').innerHTML = langData.newest_first;
        }
        if(gOrder == "asc"){
            document.getElementById('otcDESC').style.display="block";
            document.getElementById('otcASC').style.display="none";
            order = "asc";
            document.getElementById('orderText').innerHTML = langData.oldest_first;
        }
        fetchGetPosts();
    }
    function typeChange(gType){
        document.getElementById('typeAll').classList.remove('amSelectActive');
        document.getElementById('typeActive').classList.remove('amSelectActive');
        document.getElementById('typePassive').classList.remove('amSelectActive');
        if(gType == "all"){document.getElementById('typeAll').classList.add('amSelectActive');type = "all";fetchGetPosts();}
        if(gType == "Active"){document.getElementById('typeActive').classList.add('amSelectActive');type = "Aktif";fetchGetPosts();}
        if(gType == "Passive"){document.getElementById('typePassive').classList.add('amSelectActive');type = "Waiting";fetchGetPosts();}
    }
    const fetchGetPosts = () => {
        fetch(localDocs+'/rn-adaptor/pfunctions.php?islem=getProduct&userid=' + userid + '&type=' + type + '&lang=' + dil + '&order=' + order)
            .then(responsePosts => responsePosts.json())
            .then(getPosts => setGetPosts(getPosts),)
            .catch(errorPosts => console.error('Fetch error : ', errorPosts));            
    }
    const fetchGetCategory = () => {
        fetch(localDocs+'/rn-adaptor/pfunctions.php?&islem=getCategory')
            .then(responseCat => responseCat.json())
            .then(getCat => setGetCat(getCat),)
            .catch(errorCat => console.error('Fetch error : ', errorCat));
    }
    const fetchGetPost = () => {
        fetch(localDocs+'/rn-adaptor/pfunctions.php?islem=getPost&userid=' + userid + '&uniqid=' + uniqid + '&lang=' + dil)
            .then(responsePost => responsePost.json())
            .then(getPost => setGetPost(getPost), /*setText(getPost.description),setText1(getPost.t_desc)*/)
            .catch(errorPost => console.error('Fetch error : ', errorPost));
    }
    //console.log(getPost.category);
    setTimeout(() => {
        setText(getPost.description);setText1(getPost.t_desc);
    }, 300);
    useEffect(() => {
        fetchGetPosts();
        fetchGetPost();
        fetchGetCategory();
        fetchUserData();
    }, []);
    
    let lastArea = "eaUsers";
    function opArea(area) {
        document.getElementById(lastArea + "Tab").classList.remove('ntfFilterActive');
        document.getElementById(area + "Tab").classList.add('ntfFilterActive');
        lastArea = area;
    }
    let stockimg = sessionStorage.getItem('stockimg');
    function showTrash(msgid) { document.getElementById('trashArea' + msgid).style.display = 'flex'; document.getElementById('trashArea' + msgid).style.opacity = '1';}
    function hideTrash(msgid) { document.getElementById('trashArea' + msgid).style.display = 'flex'; document.getElementById('trashArea' + msgid).style.opacity = '0';}

    const [text, setText] = useState([]);
    const [text1, setText1] = useState([]);
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { setText(event.target.value); }
    const text1Change = (event) => { setText1(event.target.value); }
    
    
    let lastPostType = "imagePost";
    function postType(area) {
        document.getElementById(lastPostType + 'A').classList.remove('mypiaTabActive');
        document.getElementById(area + 'A').classList.add('mypiaTabActive');
        lastPostType = area;
        document.getElementById('imagePostArea').style.display = "none"; 
        document.getElementById('videoPostArea').style.display = "none";
        document.getElementById(area + 'Area').style.display = "block";
    }
    function mClose() {
        document.getElementById('modalMedia').style.display = "none";
        document.querySelector("#modalVideo").pause();
    }
    const openMedia = async (road, type) => {
        document.getElementById('modalMedia').style.display = "flex";
        if (type == "video") {
            document.getElementById('mmDatasImage').style.display = "none";
            document.getElementById('mmDatasVideo').style.display = "flex";
            document.getElementById('modalVideo').src = road;
        } else {
            document.getElementById('mmDatasImage').style.display = "flex";
            document.getElementById('mmDatasVideo').style.display = "none";
            document.getElementById('modalImage').src = road;
        }
    };
    
    let sendPostType = "";
    if(getPost.video){sendPostType = "video";}else{sendPostType="image";}
    const handleImageChange = (event) => { 
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]); 
        let dosya = event.target.files[0];
        handleSubmit(dosya);
    };
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const handleSubmit = (file) => {
        console.log(file);
        if (selectedFile) {
            console.log('Image selected' + selectedFile)
            const formData = new FormData();
            formData.append('file', file); formData.append('userid', userid);formData.append('uniqid', uniqid);
            for (let [key, value] of formData.entries()) {
                if (value instanceof File) {
                    console.log(`${key}: ${value.name}, ${value.size}, ${value.type}`);
                } else {
                    console.log(`${key}: ${value}`);
                }
            }
            const xhr = new XMLHttpRequest();
            
                    xhr.open('POST', localDocs+'/rn-adaptor/pfunctions.php?islem=postImageAdd', true);
                    xhr.upload.onprogress = (event) => {if (event.lengthComputable) {document.getElementById('covers').style.display='flex';const percentage = Math.floor((event.loaded * 100) / event.total);setUploadPercentage(percentage);}};
                    xhr.onload = () => {if (xhr.status === 200) {document.getElementById('covers').style.display='none';document.getElementById('coversText').innerHTML="Image uploading..";fetchGetPost();console.log('File uploaded successfully', xhr.responseText);} else {console.error('Error uploading file', xhr.statusText);}};
                    xhr.onerror = () => {console.error('Error uploading file', xhr.statusText);};
                    xhr.send(formData);
            
        }
    };
    const subFunc = async (userid, id, uniqid, func) => {
        let raw = "";
        if(func == "change"){raw = document.getElementById('imageRaw' + id).value;}
        console.log(userid + ' - ' + id + ' - '+uniqid+ ' - '+func, ' - '+raw);
        const subData = new FormData();
        subData.append('userid', userid); subData.append('id', id); subData.append('uniqid', uniqid); subData.append('func', func); subData.append('raw', raw);
        console.log(subData)
        fetch(localDocs+'/rn-adaptor/pfunctions.php?islem=subImageFunc', { method: 'POST', body: subData }).then(response => { console.log(response); fetchGetPost(); fetchGetPosts();}).catch(error => { console.log(error); });
    };
    const delPost = async (userid, id) => {
        const subData = new FormData();
        subData.append('userid', userid); subData.append('id', id);
        console.log(subData)
        fetch(localDocs+'/rn-adaptor/pfunctions.php?islem=delPost', { method: 'POST', body: subData }).then(response => { console.log(response); fetchGetPost();fetchGetPosts(); }).catch(error => { console.log(error); });
        fetchGetPosts();
    };
    const addFunc = async (userid) => {
        let eksikdata = 0;
        let title = document.getElementById('title').value;
        let category = document.getElementById('category').value;
        let price = document.getElementById('price').value;
        let stock = document.getElementById('stock').value;
        let bio = document.getElementById('bio').value;
        let t_desc = document.getElementById('t_desc').value;
        if(title == ""){eksikdata = 1;}
        if(price == ""){eksikdata = 1;}
        if(bio == ""){eksikdata = 1;}
        if(eksikdata == 1){
            alertify.error("Please fill the all areas..", 2);
        }else{
            const addData = new FormData();
            addData.append('userid', userid);
            addData.append('title', title);
            addData.append('category', category);
            addData.append('price', price);
            addData.append('stock', stock);
            addData.append('bio', bio);
            addData.append('t_desc', t_desc);
            addData.append('uniqid', uniqid);
            //console.log(addData)
            updateProgress("Updating...");
            fetch(localDocs+'/rn-adaptor/pfunctions.php?islem=addPostFunc', { method: 'POST', body: addData }).then(response => {updateProgress("Completed."); fetchGetPost();fetchGetPosts();
                console.log(response.json); 
            
            }).catch(error => { throw new Error('Error');console.log(error); });
            window.location.href='/products';
        }
    };
    const updateFunc = async (userid,postid) => {
        let title = document.getElementById('title').value;
        let category = document.getElementById('category').value;
        let price = document.getElementById('price').value;
        let stock = document.getElementById('stock').value;
        let bio = document.getElementById('bio').value;
        let t_desc = document.getElementById('t_desc').value;
        const addData = new FormData();
        addData.append('postid', postid);
        addData.append('userid', userid);
        addData.append('title', title);
        addData.append('category', category);
        addData.append('price', price);
        addData.append('stock', stock);
        addData.append('bio', bio);
        addData.append('t_desc', t_desc);
        addData.append('uniqid', uniqid);
        console.log(category);
        updateProgress("Updating...");
        fetch(localDocs+'/rn-adaptor/pfunctions.php?islem=updatePostFunc', { method: 'POST', body: addData }).then(response => {updateProgress("Completed."); console.log(response); fetchGetPost();fetchGetPosts(); }).catch(error => { throw new Error('Error');console.log(error); });
        //window.location.href='/posts';
    };

    function updateProgress(message) {
        document.getElementById('updateP').style.display = "block";
        document.getElementById('updateP').value = message;
        document.getElementById('updateP').innerHTML = message;
        console.log(message);
    }
    const titleChange = (event) => {setGetPost({title:  document.getElementById('title').value});fetchGetPost(); /*dataChange('title', document.getElementById('title').value);*/};
    const categoryChange = (event) => {setGetPost({category:  document.getElementById('category').value});fetchGetPost();if(uniqid > 0){updateFunc(userid, uniqid);}};
    //const categoryChange = (event) => {setGetPost({category:  document.getElementById('category').value});fetchGetPost();setGetPost({category:  document.getElementById('category').value});};
    const priceChange = (event) => {setGetPost({price:  document.getElementById('price').value});fetchGetPost();};
    const stockChange = (event) => {setGetPost({stock:  document.getElementById('stock').value});fetchGetPost();};
    let yuklendimi = 0;
    //let biotext = (he.decode(getPost.description), 'text/html');
    setTimeout(function () {if(yuklendimi == 0){yuklendimi=1;if(getPost.description){setText((parser.parseFromString(he.decode(getPost.description), 'text/html')).documentElement.textContent);}};
    if(getPost.video){document.getElementById('videoPostArea').style.display="block";}
    }, 300);
    
    function opMobMenu(opmmType){
        if(opmmType == "open"){
            document.getElementById('allMessages').style.left = "0px";
        }else{
            document.getElementById('allMessages').style.left = "-300px";
        }
    }
    return (
        <div>
            <div id='modalMedia' className='modalMedia'>
                <a onClick={() => mClose()} className='mmClose'></a>
                <div className='mmDatas'>
                    {/*<a onClick={() => prevMedia()} className='mmDatasLeft' id='mmDatasLeft'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    <div className='mmDatasImage' id='mmDatasImage'>
                        <img id='modalImage' src={stockimg} />
                    </div>
                    <div className='mmDatasVideo' id='mmDatasVideo'>
                        <video id='modalVideo' controls><source id='modalVideoSrc' src={stockimg} type="video/mp4" /></video>
                    </div>
                    {/*<a onClick={() => nextMedia()} className='mmDatasRight' id='mmDatasRight'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                </div>
            </div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='allMessages' id='allMessages'>
                    <div className='allMessagesBtn' onClick={()=>opMobMenu('open')}><img src={require('../assets/icons/posts.webp')}/></div>
                    <div className='amHeader'>
                        <a onClick={()=>window.history.back()} className='leftArrow webitem'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <a onClick={()=>opMobMenu('close')} className='leftArrow mobileitem'><img src={require('../assets/icons/leftarrow.webp')} /></a>
                        <h3>{langData.my_products}</h3>
                    </div>
                    <div className='amFilter'>
                        <p id='orderText'>{langData.newest_first}</p>
                        <img id='otcASC' onClick={()=>orderChange('asc')} src={require('../assets/icons/filter.webp')} />
                        <img id='otcDESC' onClick={()=>orderChange('desc')} src={require('../assets/icons/filter.webp')} />
                    </div>
                    <div className='amSelect'>
                        <a id='typeAll' className='amSelectActive' onClick={()=>typeChange('all')}>{langData.all}</a>
                        <a id='typeActive' onClick={()=>typeChange('Active')}>{langData.approved}</a>
                        <a id='typePassive' onClick={()=>typeChange('Passive')}>{langData.waiting_for_approval}</a>
                    </div>
                    {getPosts ? (
                        <div>
                            {getPosts.map(posts =>
                                <div key={posts.id} onMouseOver={() => showTrash(posts.id)} onMouseOut={() => hideTrash(posts.id)} className='myPosts'>
                                    {posts.video ? (<video width="100%;" height="150px" ><source src={posts.video} type="video/mp4" /></video>):(<img className='mypImage' src={posts.image} />)}
                                    <div className='mypCover'></div>
                                    <div className='mypTitle'>
                                        <a href={'/user-detail/' + posts.otherid}>{((parser.parseFromString(he.decode(posts.title), 'text/html')).documentElement.textContent)}</a>
                                    </div>
                                    <div id={"trashArea" + posts.id} className='mypFunc'>
                                        <a href={'/products/'+posts.id}><img src={require('../assets/icons/settings.webp')} /></a>
                                        <a onClick={()=>delPost(userid, posts.id)}><img src={require('../assets/icons/trash.webp')} /></a>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (<div style={{padding : '10px'}}>No Product Yet</div>)}
                </div>
                <div className='nowMessages'>
                    <div id='newMessageArea'>
                    <div className='nowMessageCover' id='covers'>
                        <div>
                            <p id='coversText'>Document Uploading...</p>
                            <p>{uploadPercentage}%</p>
                            {uploadPercentage > 0 && <progress value={uploadPercentage} max="100">{uploadPercentage}%</progress>}
                        </div>
                    </div>
                        <div className='amHeader'>
                            <h3>{langData.product}</h3>
                        </div>
                        <div className='mypInputAreas'>
                            <div className='mypInputArea mypHalf'>
                                <label>{langData.title}</label>
                                {getPost.title?(
                                    <input id='title' defaultValue={(parser.parseFromString(he.decode(getPost.title), 'text/html')).documentElement.textContent}/>
                                ):(
                                    <input id='title'/>
                                )}
                            </div>
                            <div className='mypInputArea mypHalf'>
                                <label>{langData.category}</label>
                                {getCat ? (
                                    <select value={getPost.category} id="category"  onChange={()=>categoryChange()}>
                                        {getCat.map(category =>
                                            <option key={category.id} value={category.id}>{((parser.parseFromString(he.decode(category.title), 'text/html')).documentElement.textContent)}</option>
                                        )}
                                    </select>
                                ) : ''}
                            </div>
                            <div className='mypInputArea mypHalf'>
                                <label>{langData.price}</label>
                                <input id='price' defaultValue={getPost.price}/>
                            </div>
                            <div className='mypInputArea mypHalf'>
                                <label>Stock</label>
                                <input id='stock' defaultValue={getPost.stock}/>
                            </div>
                            
                            <div className='mypInputArea'>
                                <div id={"emoji1"} className='mypdEmojiArea'>
                                    {emojis.map((emoji) => (
                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                    ))}
                                </div>
                                <div className='mypdArea'>
                                    <label>Short {langData.description}</label>
                                    {/*<div>{getPost.description ?(setText(he.decode(getPost.description), 'text/html')):('')};</div>*/}
                                    <textarea id={'t_desc'} defaultValue={text1} onChange={text1Change} onLoad={()=>alert('here')} placeholder={'Short '+langData.description}></textarea>
                                    {/*<a onClick={() => { document.getElementById('emoji1').style.display === "block" ? (document.getElementById('emoji1').style.display = "none") : (document.getElementById('emoji1').style.display = "block") }
                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>*/}
                                </div>
                            </div>
                            <div className='mypInputArea'>
                                <div id={"emoji1"} className='mypdEmojiArea'>
                                    {emojis.map((emoji) => (
                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                    ))}
                                </div>
                                <div className='mypdArea'>
                                    <label>{langData.description}</label>
                                    {/*<div>{getPost.description ?(setText(he.decode(getPost.description), 'text/html')):('')};</div>*/}
                                    <textarea id={'bio'} defaultValue={text} onChange={textChange} onLoad={()=>alert('here')} placeholder={langData.description}></textarea>
                                    {/*<a onClick={() => { document.getElementById('emoji1').style.display === "block" ? (document.getElementById('emoji1').style.display = "none") : (document.getElementById('emoji1').style.display = "block") }
                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>*/}
                                </div>
                            </div>
                            <div className='mypImageArea'>
                                {uniqid > 0 ?(
                                <div>
                                    <div id='imagePostArea'>
                                        <a onClick={() => document.getElementById('newImage').click()}>{langData.add_new_image}</a>
                                        <input type="file" accept="image/*" onChange={(event) => { handleImageChange(event); }} className='hidden' id='newImage' />
                                        <p id='videoPostText' className='hidden'>Uploading..</p>
                                        <div className='ipaImages'>
                                            {getPost.images ? (
                                                <div className='ipaImageDiv'>
                                                    {getPost.images.map(images =>
                                                        <div key={images.id} className='ipaImage'>
                                                            <img onClick={() => { openMedia(images.image, 'image') }} id={'subImage'+images.id} src={images.image} />
                                                            {images.row == 0 ? (
                                                                <div>
                                                                    <p>{langData.main}</p>
                                                                    <a onClick={() => subFunc(userid, images.id, uniqid, 'del')} className='setMainDelete'>{langData.delete}</a>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <input type='number' onChange={() => subFunc(userid, images.id, uniqid, 'change')} id={'imageRaw' + images.id} defaultValue={images.row} />
                                                                    <a onClick={() => subFunc(userid, images.id, uniqid, 'set')} className='setMain'>{langData.set_main}</a>
                                                                    <a onClick={() => subFunc(userid, images.id, uniqid, 'del')} className='setMainDelete'>{langData.delete}</a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : ('')}
                                        </div>
                                    </div>
                                </div>
                                ):(
                                <div>
                                    <div>
                                        <div id='imagePostArea'>
                                            <a onClick={() => document.getElementById('newImage').click()}>{langData.add_new_image}</a>
                                            <input type="file" accept="image/*" onChange={(event) => { handleImageChange(event); }} className='hidden' id='newImage' />
                                            <div className='ipaImages'>
                                                {getPost.images ? (
                                                    <div className='ipaImageDiv'>
                                                        {getPost.images.map(images =>
                                                            <div key={images.id} className='ipaImage'>
                                                                <img onClick={() => { openMedia(images.image, 'image') }} id={'subImage'+images.id} src={images.image} />
                                                                {images.row == 0 ? (
                                                                    <div>
                                                                        <p>{langData.main}</p>
                                                                        <a onClick={() => subFunc(userid, images.id, uniqid, 'del')} className='setMainDelete'>{langData.delete}</a>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <input type='number' onChange={() => subFunc(userid, images.id,uniqid, 'change')} id={'imageRaw' + images.id} defaultValue={images.row} />
                                                                        <a onClick={() => subFunc(userid, images.id, uniqid, 'set')} className='setMain'>{langData.set_main}</a>
                                                                        <a onClick={() => subFunc(userid, images.id, uniqid, 'del')} className='setMainDelete'>{langData.delete}</a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : ('')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className='postUpdateArea'>
                                    {uniqid > 0 ? (
                                        <a onClick={()=>updateFunc(userid, uniqid)}>{langData.save}</a>
                                    ):(
                                        <a onClick={()=>addFunc(userid)}>{langData.save}</a>
                                    )}
                                    <p id='updateP'></p>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Posts;