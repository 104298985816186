import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Left from './Left';
import he from 'he';
const parser = new DOMParser();
function Orders({ langData }) {
    let { type, uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');

    const [getSale, setGetSale] = useState([]);
    const [getSales, setGetSales] = useState([]);
    const [getOrders, setGetOrders] = useState([]);
    const [getOrder, setGetOrder] = useState([]);
    let fetchType = "all";
    const fetchGetSales = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions.php?islem=getSales&userid=' + userid)
            .then(responseSales => responseSales.json())
            .then(getSales => setGetSales(getSales))
            .catch(errorSales => console.error('Fetch error : ', errorSales));
    }
    const fetchGetSale = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions.php?islem=getSale&userid=' + userid+'&uniqid='+uniqid)
            .then(responseSale => responseSale.json())
            .then(getSale => setGetSale(getSale))
            .catch(errorSale => console.error('Fetch error : ', errorSale));
    }
    const fetchGetOrders = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions.php?islem=getOrders&userid=' + userid)
            .then(responseOrders => responseOrders.json())
            .then(getOrders => setGetOrders(getOrders))
            .catch(errorOrders => console.error('Fetch error : ', errorOrders));
    }
    const fetchGetOrder = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions.php?islem=getOrders&userid=' + userid+'&uniqid='+uniqid)
            .then(responseOrder => responseOrder.json())
            .then(getOrder => setGetOrder(getOrder))
            .catch(errorOrder => console.error('Fetch error : ', errorOrder));
    }
    useEffect(() => {
        fetchGetSale();
        fetchGetSales();
        fetchGetOrder();
        fetchGetOrders();
    }, []);
    console.log(getOrders);
    setTimeout(() => {
        if(type == "sales"){chTab('sales');if(uniqid){chTab('sale');}}
        if(type == "orders"){chTab('orders');if(uniqid){chTab('order');}}
        
    }, 300);
    
    let stockimg = localStorage.getItem('stockimg');
    const parser = new DOMParser();
    let ltab = "sales";
    function chTab(gTab){
        document.getElementById('salesA').classList.remove('atBodyActive');
        document.getElementById('ordersA').classList.remove('atBodyActive');
        if(gTab == "orders" || gTab == "order"){document.getElementById('ordersA').classList.add('atBodyActive');}
        if(gTab == "sales" || gTab == "sale"){document.getElementById('salesA').classList.add('atBodyActive');}
        document.getElementById('sales').style.display = "none";
        document.getElementById('sale').style.display = "none";
        document.getElementById('orders').style.display = "none";
        document.getElementById('order').style.display = "none";
        document.getElementById(gTab).style.display = "block";
    }
    const chStatus = async (uniqid) => {
        let nStatus = document.getElementById('orderStatus').value;
        const data = new FormData();
        data.append('status', nStatus);
        data.append('uniqid', uniqid);
        fetch(localDocs+'/rn-adaptor/sfunctions.php?islem=orderStatusChange', { method: 'POST', body: data }).then(response => {console.log(response.json);fetchGetSale(); }).catch(error => { throw new Error('Error');console.log(error); });
    };
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='allTra'>
                    <div className='atHeader'>
                        <a onClick={()=>window.history.back()} className='leftArrow'><img src={require('../assets/icons/arrow.webp')} /></a>
                        <h3>{langData.transactions}</h3>
                    </div>
                    <div className='atBody'>
                        <a className='atBodyActive' onClick={()=>chTab('sales')} id='salesA'>Sales<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='ordersA' onClick={()=>chTab('orders')}>Orders <img src={require('../assets/icons/arrow.webp')} /></a>
                        
                        {/*<a>My Cards <img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    </div>
                </div>
                <div className='selectTra'>
                    <div id='myWallet' className='myWallet'>
                        <div className='myWalletCredit' id='sales'>
                            <p>Sales</p>
                            {getSales ? (
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>ID</td>
                                                <td>{langData.total}</td>
                                                <td>{langData.quantity}</td>
                                                <td>{langData.date}</td>
                                                <td>{langData.status}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {getSales.map(gsales =>
                                            <tr onClick={()=>window.location.href='/orders/sales/'+gsales.uniqid} key={gsales.id}>
                                                <td>{gsales.uniqid}</td>
                                                <td>{gsales.totalPrice}$</td>
                                                <td>{gsales.totalCount}</td>
                                                <td>{gsales.date}</td>
                                                <td>{gsales.status}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ''}
                        </div>
                        <div className='myWalletCredit' id='sale'>
                            {getSale ? (
                                <div>
                                    <h5><b>ID : </b>{getSale.uniqid}</h5>
                                    <div className='orderUserInfo'>
                                        <p><b>Customer :</b> {getSale.name} {getSale.surname} - {getSale.email} - {getSale.phone}</p>
                                        <p><b>Address :</b> {getSale.address} - {getSale.country} / {getSale.state}</p>
                                        <p><b>Order Note :</b> {getSale.orderNote}</p>
                                    </div>
                                    {getSale.products ?(
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>ID</td>
                                                <td>{langData.title}</td>
                                                <td>{langData.price}</td>
                                                <td>{langData.quantity}</td>
                                                <td>{langData.total}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {getSale.products.map(products =>
                                            <tr key={products.id}>
                                                <td>{products.id}</td>
                                                <td>{products.title}</td>
                                                <td>{products.price}$</td>
                                                <td>{products.quantity}</td>
                                                <td>{products.total}$</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    ):''}
                                    <div className='orderUserInfo'>
                                        <p className='mt-4'><b>Status :</b> {getSale.status} - {getSale.date}</p>
                                        <p className='mt-1'><b>Total :</b> {getSale.totalPrice}$ - Quantity : {getSale.totalCount} </p>
                                        <select id='orderStatus' onChange={()=>chStatus(uniqid)}>
                                            <option value='0'>Change Status</option>
                                            <option value='Pending.'>Pending.</option>
                                            <option value='Shipped.'>Shipped.</option>
                                            <option value='Completed.'>Completed.</option>
                                            <option value='Canceled.'>Canceled.</option>
                                        </select>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                        <div className='myWalletCredit' id='orders'>
                            <p>Orders</p>
                            {getOrders ? (
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>ID</td>
                                                <td>{langData.total}</td>
                                                <td>{langData.quantity}</td>
                                                <td>{langData.date}</td>
                                                <td>{langData.status}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {getOrders.map(gsales =>
                                            <tr onClick={()=>window.location.href='/orders/orders/'+gsales.uniqid} key={gsales.id}>
                                                <td>{gsales.uniqid}</td>
                                                <td>{gsales.totalPrice}$</td>
                                                <td>{gsales.totalCount}</td>
                                                <td>{gsales.date}</td>
                                                <td>{gsales.status}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ''}
                        </div>
                        <div className='myWalletCredit' id='order'>
                            <p>Order</p>
                            {getOrder ? (
                                <div>
                                    <h5><b>ID : </b>{getSale.uniqid}</h5>
                                    <div className='orderUserInfo'>
                                        <p><b>Customer :</b> {getSale.name} {getSale.surname} - {getSale.email} - {getSale.phone}</p>
                                        <p><b>Address :</b> {getSale.address} - {getSale.country} / {getSale.state}</p>
                                        <p><b>Order Note :</b> {getSale.orderNote}</p>
                                    </div>
                                    {getSale.products ?(
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>ID</td>
                                                <td>{langData.title}</td>
                                                <td>{langData.price}</td>
                                                <td>{langData.quantity}</td>
                                                <td>{langData.total}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {getSale.products.map(products =>
                                            <tr key={products.id}>
                                                <td>{products.id}</td>
                                                <td>{products.title}</td>
                                                <td>{products.price}$</td>
                                                <td>{products.quantity}</td>
                                                <td>{products.total}$</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    ):''}
                                    <div className='orderUserInfo'>
                                        <p className='mt-4'><b>Status :</b> {getSale.status} - {getSale.date}</p>
                                        <p className='mt-1'><b>Total :</b> {getSale.totalPrice}$ - Quantity : {getSale.totalCount} </p>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Orders;